import { Dropdown, FormLabelIcon, TitleBar } from '@/components/Common';
import { images } from '@/config';
import classNames from 'classnames';
import React from 'react';
import ReCAPTHA from 'react-google-recaptcha';
import { Field } from 'redux-form/immutable';
import { Button, Dimmer, Image, Loader } from 'semantic-ui-react';
import { FieldAgreement, FieldInput } from '../../Form';
import enhance from './enhancer';
import { prefecture } from './utils';

function CognitoCallback(props) {
  const {
    handleSubmit,
    submitSocialSignUp,
    isLoading,
    submitRecapcha,
    displayRecapchaError,
    isCallBack,
  } = props;

  return (
    <>
      <Dimmer
        className="a__cognito_callback"
        active={isCallBack}
        style={{ width: '100vw', height: '100vh', backgroundColor: 'white' }}
      >
        <Loader />
      </Dimmer>
      <div
        className={classNames({
          social: true,
          flex: true,
          app_hidden: isCallBack,
        })}
      >
        <header className="app_header flex-beta sns">
          <Image src={images.logo_header} className="logo" />
        </header>
        <div className="wrapper">
          <TitleBar title="会員情報入力" />

          <form className="flex" onSubmit={handleSubmit(submitSocialSignUp)}>
            <div className="amplify-container">
              <div className="form-item">
                <div className="flex_required">
                  <label>ニックネーム</label>
                  <FormLabelIcon value="必須" />
                </div>
                <Field
                  name="displayName"
                  className="displayName"
                  placeholder="ニックネーム"
                  component={FieldInput}
                  disabled={isLoading}
                  maxLength={18}
                />
                <Field
                  search
                  fluid
                  className="prefecture"
                  name="prefecture"
                  component={Dropdown}
                  placeholder="都道府県"
                  options={prefecture}
                  noResultsMessage="該当する都道府県はありせん"
                />
              </div>
              <div className="field-group recaptcha">
                <ReCAPTHA
                  sitekey="6LdU7H8cAAAAAMa2O9l5ASK7zMI1VbuqyOTzx4xW"
                  onChange={submitRecapcha}
                />
                <div
                  className={classNames({
                    login_error_message: true,
                    display_error: displayRecapchaError,
                  })}
                >
                  選択してください
                </div>
              </div>
              <div className="field-group agreement">
                <Field
                  name="isAgreement"
                  className="is_agreement"
                  disabled={isLoading}
                  component={FieldAgreement}
                />
              </div>
              <Button
                className="btn_google submit_login"
                loading={isLoading}
                disabled={isLoading}
              >
                <div className="grid">
                  <span className="content flex">登録する</span>
                </div>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default enhance((props) => <CognitoCallback {...props} />);
